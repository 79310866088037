

function Virgin(id, link, paginate) {
    // console.log(id + ' ' + link+' '+paginate);
    var tab = $('#tab'+id);
    var pagination = $('#pagination');
    //$("#nav>li.active").removeClass("active");
    //$('#' + id).addClass('active');
    //tab.fadeOut(1000);
    $('#loading').fadeIn(1000);
    tab.html('');

    //page.empty();
    $.ajax({
        type: 'GET',
        url: link + '/project/tag/' + id + '?page='+paginate,
        dataType: 'html',
        beforeSend: function () {
            $('#loading').fadeIn(1000);
        },
        complete: function (XMLHttpRequest) {

            // pane.removeClass('loading');
            if (XMLHttpRequest.status == 200 && XMLHttpRequest.responseText != '') {
                var res = JSON.parse(XMLHttpRequest.responseText);
                var p = res.page;
                //page.append(p);
                // console.log(res.data);
                all = res.data;
                //console.log(all);
                $('#loading').fadeOut(1000, function(){
                    tab.html(all);
                });

                // if (id == 0) {
                //     var big = res.big;
                //     big.forEach(function (a) {
                //         if (a.featured == 1) {
                //             var result = '<div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 sorting-item">' +
                //                 '<div class="case-item align-center big mb60">' +
                //                 '<div class="case-item__thumb mouseover lightbox shadow animation-disabled">' +
                //                 '<img src="';
                //             result += link + '/' + a.img_url;
                //             // console.log(result);
                //             result += '" alt="our case">' +
                //                 '</div>' +
                //                 '<a href="' + link + '/project/' + convertToSlug(a.title) + '"><h5' +
                //                 'class="case-item__title">' + a.title + '</h5></a>' +
                //                 '</div>' +
                //                 '</div>';
                //             pane.append(result);
                //         }
                //     });
                //     all.data.forEach(function (a) {
                //         if(a.featured==0)
                //             makeSmall(a);
                //     });
                // }
                // else {
                //     all.data.forEach(makeSmall);
                // }
            }
        }
    });

    function makeSmall(a) {
        var result = '<div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 sorting-item">' +
            '<div class="case-item align-center mb60">' +
            '<div class="case-item__thumb mouseover lightbox shadow animation-disabled">' +
            '<img src="';
        result += link + '/' + a.img_url;
        // console.log(result);
        result += '" alt="our case">' +
            '</div>' +
            '<a href="' + link + '/project/s' + convertToSlug(a.title) + '"><h5' +
            'class="case-item__title">' + a.title + '</h5></a>' +
            '</div>' +
            '</div>';
        console.log('test');
        pane.append(result);
    }
}
function convertToSlug(Text) {
    return Text
        .toLowerCase()
        .replace(/[^\w ]+/g, '')
        .replace(/ +/g, '-')
        ;
}

$(document).ready(function(){
    new WOW().init();
});